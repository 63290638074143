<template>
  <div>
    <h2> Datos estadísticos </h2>
    <h3> <i> <b> En construcción </b> </i> </h3>

    <v-icon name="bi-gear" animation="spin" speed="slow" scale="3" fillcolor="#222F3D"></v-icon>

    <p> En este espacio se podrá acceder a información estadística proveniente de diversas fuentes. Actualmente esta información es registrada y almacenada en diversos formatos, tanto impresos como digitales, lo que dificulta un análisis integral de los datos. Dentro de este portal se recopilarán datos históricos y modelos en un formato digital estandarizado, los cuales estarán disponibles para generación de gráficos y descarga para uso externo. </p>
  </div>
</template>

<script>
/* import { mapState, mapActions } from 'vuex' */

export default {
  name: 'StatsPage',
  components:{

  },
  data() {
    return {
      name: 'StatsPage'
      }
  },
  computed: {
 /*    ...mapState('modulo', ['estados']) */
  
  },
  methods:{
    /* ...mapActions('modulo', ['acciones']) */
  }


}
</script>

<style>

</style>