export function handleResponse(response) {
    // Todas las respuestas de la API devuelven JSON  
    return response.json().then(datajson => {  
         // MANEJO DE ERRORES     
         if (!response.ok) {
             // BAD REQUEST 
             if (response.status === 400) {
                 // la accion correspondiente dependera de la solicitud
                 console.log('@HandleResponse::Error 400') 
             }
             // UNAUTHORIZED
             if (response.status === 401) {
                 console.log('@HandleResponse::Error 401')
                 // auto logout 
                 //logout();
                 //location.reload(true);
             }
             // NOT FOUND
             if (response.status === 404) {
                 // la accion correspondiente dependera de la solicitud   
                 console.log('@HandleResponse::Error 404')              
             }
             // NOT ALLOWED
             if (response.status === 405) {
                 // la accion correspondiente dependera de la solicitud 
                 console.log('@HandleResponse::Error 405')               
             }
             // INTERNAL SERVER ERROR
             if (response.status === 500) {
                 // la accion correspondiente dependera de la solicitud
                 // usado para errores logicos
                 console.log('@HandleResponse::Error 500')
             }
             
             
             // En todos los casos, se devuelve el mensaje de error
             const error = (datajson && datajson.mensaje) || (datajson && datajson.message) || "Error en API Request - No identificado";
             return Promise.reject(error);
         }
         // NO HAY ERROR --> response.ok=true (200)
         return datajson;
    });
 }