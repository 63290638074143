<template>
  <div id="app">
    <!-- <HeaderTop/> -->
    <router-view></router-view>
  </div>
</template>

<script>
/* import HelloWorld from './components/HelloWorld.vue' */
/* import HeaderTop from './components/HeaderTop.vue' */

export default {
  name: 'App',
  components: {
  /*     HelloWorld */
   /*  HeaderTop */
  },
  watch:{
    $route(to, from){
      console.log('cambiando ruta de', to , ' a ', from)
    } 
  }
}
</script>

<style>
*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;
}

/*Colour pallete*/
:root{
  --pink: rgb(239, 71, 111);
  --orange: rgb(255, 209, 102);
  --green: rgb(6, 214, 160);
  --light-blue: rgb(17, 138, 178);
  --blue: rgb(7, 59, 76);
}
body{
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;
}
#app {
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--blue);
  margin-top: 0px;
  padding-top: var(--header-height);
}


</style>
