<template>
  <div class="contenedor-inicio">
    <header class="header-inicio"> 
      <img class="logo-hidroar" src="../assets/logo_hidroar.jpg" alt="Logo del Portal" >
      <h1> Portal Hidroclimatológico del Municipio de Tornquist </h1> 
      <router-link to="/home" class="btn btn-link ingreso" > Ingreso público </router-link>
    </header>
    <main class="principal">     

      <section class="slider-inicio">
        <vue-flux
          :options="fluxOptions"
          :images="fluxImages"
          :transitions="fluxTransitions"
          ref="slider">
              <flux-pagination slot="pagination"></flux-pagination>
        </vue-flux>
      </section>

      <section> 
        <p> Este Portal de Datos Abiertos de Recursos Hídricos del Municipio nació a partir de un <b> FONDO DE INNOVACIÓN TECNOLÓGICA DE BUENOS AIRES (FITBA) </b> del Gobierno de la Provincia de Buenos Aires. El objetivo fue el diseño con la finalidad de monitoreo, seguimiento y evaluación de los recursos hídricos, basado en geotecnologías y datos in situ en tiempo real. La contribución mayor que se aporta es brindar información que ayude a tomar decisiones para la gestión del recurso hídrico a escala municipal. </p>
        <p> La presente propuesta constituye una iniciativa interdisciplinaria e interinstitucional que responde a la necesidad de generar herramientas de planificación y gestión territorial ante un escenario complejo respecto de las características naturales y las problemáticas relacionadas con los recursos hídricos en el Municipio de Tornquist. La misma combina el saber-hacer del equipo para la instrumentación y puesta en funcionamiento de un sistema integrado de monitoreo que sirva de apoyo a la toma de decisiones en materia de gestión de los recursos hídricos para el Municipio. </p> 
        <div class="logos-inicio">
          <a href="https://www.gba.gob.ar/ciencia/fondo_de_innovacion_tecnologica_de_buenos_aires"> <img src="../assets/Marca _ Fondo de Innovación Tecnológica_Mesa de trabajo 1 copia 3.png" alt="Fondo de Innovación Tecnológica de Buenos Aires" > </a>
          <a href="https://tornquist.gob.ar/"> <img src="../assets/logo_municipio_Tornquist.png" alt="Municipio de Tornquist" > </a>
          <a href="https://www.gba.gob.ar/"> <img src="../assets/Ministerio -_Ministerio + Provincia-02.png" alt="Gobierno de la Provincia de Buenos Aires" > </a>
        </div>
      </section>

      <section> 
        <p>
          El equipo de investigación está integrado por investigadoras/es, agentes y becarias/os con trayectoria en el campo de los recursos hídricos y con experiencia directa vinculada con el territorio de Tornquist y sus problemáticas ambientales.
          El equipo posee experticia en temas vinculados con:
        </p> 
        <ul>
          <li> Las líneas <b> Climatología, Hidrografía, Modelización, Ordenamiento Territorio</b> del Grupo de Geografía Física Aplicada (GGFA) del Departamento de Geografía y Turismo (DGyT-UNS) </li>
          <li> La línea <b>Hidrogeología</b> del Centro de Geología Aplicada, Agua y Medio Ambiente (CGAMA, CIC-UNS) y el Departamento de Geología (UNS) </li>
          <li> La línea <b>Agroclimática</b> de la Bolsa de Cereales y Productos de Bahía Blanca (BCPBB) </li>
          <li> La línea de <b>Procesamiento Digital de Imágenes y Ciencias de las Imágenes</b> del Departamento de Ingeniería Eléctrica y Computadoras (DIEC-UNS) y el Instituto de Investigaciones en Ingeniería Eléctrica (UNS-CONICET) </li>
          <li> La línea de <b>Economía Ambiental</b> del Departamento de Economía (DE - UNS). </li>
        </ul>

        <div class="logos-inicio">
          <a href="https://ggfaweb.wordpress.com/"> <img src="../assets/logo_GGFA.png" alt="Grupo de Geografía Física Aplicada" > </a>
          <a href="http://www.geografiayturismo.uns.edu.ar/"> <img src="../assets/logo_dgyt.png" alt="Departamento de Geografía y Turismo" > </a>
          <a href="https://www.uns.edu.ar/deptos/geologia"> <img src="../assets/logo_geologia.png" alt="Departamento de Geología" > </a>
          <a href="https://bcp.org.ar/estimaciones/informes-climaticos.asp"> <img src="../assets/logo-bcp.jpeg" alt="Bolsa de Cereales y Productos de Bahía Blanca" > </a>
          <a href="https://icic.conicet.gov.ar/"> <img src="../assets/logo_ICIC.png" alt="Instituto de Ciencias e Ingeniería de la Computación" > </a>
          <a href="https://www.uns.edu.ar"> <img src="../assets/logo_UNS.png" alt="Universidad Nacional del Sur" > </a>
          
        </div>

      </section>

      <section>
        <p>  La <b>Agenda 2030 de Desarrollo Sostenible</b> de la <b>ONU</b> busca la acción para poner fin a la pobreza, proteger el planeta y mejorar las vidas y las perspectivas de las personas en todo el mundo. En este sentido, el portal intenta abordar el Objetivo de Desarrollo Sostenible 6 <b>"Agua limpia y saneamiento" (ODS 6)</b>.
El ODS persigue garantizar el accceso de las personas a agua potable segura y a servicios básicos de saneamiento adecuados. Además, propone la protección y gestión sostenible de los recursos hídricos, incluidos los ecosistemas relacionados con el agua, como los ríos, lagos y humedales. </p>
      </section>

      <section>
        <p>
          <a href="https://portalhidro.ar/webdisk/Guia_BP.pdf">  <img src="../assets/guia_bph.jpg" class="guide-bph"> </a> En la misma línea de trabajo en la que se desarrolla este Portal, se ha creado además la <b>Guía de Buenas Prácticas hídricas</b>, que constituye una herramienta para que los gestores del partido de Tornquist puedan identificar la situación de los recursos hídricos y sus principales problemáticas. De esta manera se mejorará la gestión y planificación del desarrollo territorial a mediano y largo plazo.  
Está compuesta de 3 capítulos, el primero detalla las problemáticas más relevantes del partido: crecidas, inundaciones, erosión hídrica, calidad de agua y escasez e incluye una breve descripción e información ampliatoria. En el segundo capítulo se aborda el concepto de buena práctica hídrica y se desarrollan las que se trabajan en la guía (Soluciones Basadas en la Naturaleza, Intervenciones de planificación, Intervenciones tecnológicas y Educación ambiental). En el último capítulo se presentan diferentes ejemplos de buenas prácticas en formato de fichas, que pueden ser aplicables a la situación hídrica del partido de Tornquist.
La guía es un aporte hacia la gestión sostenible e integral de los recursos hídricos del partido y busca fortalecer la capacidad de acción del municipio. Está pensada de manera transversal a las diferentes áreas de gobierno para que su implementación sea efectiva. 

        </p>

      </section>


      <section>
        <p> El Portal provee una infraestructura de <b>Sistema de Información Geográfica en línea (SIG Web)</b>, generando una herramienta fundamental para avanzar en la digitalización y normalización de información referida al recurso hídrico. En esta infraestructura es posible integrar múltiples fuentes de datos, como estaciones meteorológicas, imágenes satelitales, instrumental específico (freatímetros, limnígrafos, etc) y en particular define un esquema de referencia para unificar registros históricos que han sido recopilados previamente en distintos formatos. </p>
        <p> Una vez ingresados los datos al sistema, surgen múltiples herramientas de visualización y análisis, como la representación de capas en formato vectorial y raster, consulta en tiempo real de variables climatológicas o generación de gráficos históricos. Mediante técnicas de procesamiento de imágenes y fusión de sensores, se generan productos especiales para análisis específicos, como diversos índices de vegetación, humedad, agua y otros que pueden ser modelados y producidos a medida para las entidades involucradas que lo soliciten. </p>
        <p> Actualmente se cuenta con: </p>
        <ul>
          <li> Visualización de capas de información geográfica </li>
          <li> Filtros para la visualización de capas </li>
          <li> Herramientas de análisis </li>
          <li> Información de estaciones meteorológicas </li>
        </ul>
        <div class="logos-inicio">
          <img class="c2" src="../assets/inicio/vistas/vista_capas_1.png" alt="Funcionalidades del portal - Vista de capas" >
          <img class="c2" src="../assets/inicio/vistas/vista_estaciones_2.png" alt="Funcionalidades del portal - Vista de estaciones" >
          <img class="c2" src="../assets/inicio/vistas/vista_precipitaciones_3.png" alt="Funcionalidades del portal - Vista de precipitaciones" >
        </div>
        
      </section>

      <section> 
        <p> Para más información puede escribirnos a: <a href="mailto:info@portalhidro.ar"> info@portalhidro.ar </a> </p> 
      </section>
    </main>    
  </div>
</template>

<script>
import { VueFlux, FluxPagination, Transitions } from 'vue-flux';

export default {
  components: {
      VueFlux,
      FluxPagination
   },
  name: 'LoginPage',
  data() {
    return {
        msg: 'Inicio de sesión en Hidro.ar',
        fluxOptions: {
          autoplay: true
        },
        fluxImages: [ 
          require('@/assets/inicio/slider1.jpg'), 
          require('@/assets/inicio/slider2.jpeg'), 
          require('@/assets/inicio/slider3.jpg'),
          require('@/assets/inicio/slider4.jpg'), 
          require('@/assets/inicio/slider5.jpg'),
          require('@/assets/inicio/slider6.jpeg'), 
          require('@/assets/inicio/slider7.jpg'), 
          require('@/assets/inicio/slider8.jpg'),
          require('@/assets/inicio/slider9.jpg'), 
          require('@/assets/inicio/slider10.jpeg'),
          require('@/assets/inicio/slider11.jpeg') ],
        fluxTransitions: {
          /* transitionBook: Transitions.transitionBook */
          /* transitionCube: Transitions.transitionCube, */
          transitionBlocks1: Transitions.transitionBlocks1,
          transitionBlocks2: Transitions.transitionBlocks2,
          transitionSwipe: Transitions.transitionSwipe
        }
    }
  }
}
</script>

<style>

.contenedor-inicio{
  position: absolute;
  width: 100%;
  top: 0;
}

.header-inicio{
  height: 90px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 0 20px;
  margin-bottom: 20px;
  gap: 10px;
}
h1{
  font-size: 1.8rem;
}
@media screen and (max-width: 650px){
  h1{
    font-size: 1.5rem;
  }
}


.logo-hidroar{
  height: 90%;
  width: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}

.ingreso{
  padding: 10px 20px;
  background-color: #009688;
  color: white;
  text-decoration: none;

  margin-left: auto;
}
.principal{
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
}

.principal>section{
  width: 85%;
  background-color: #e8ebf1;
  margin: 20px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgb(8 26 81 / 70%);
  color: #181616;
  text-align: justify;
  line-height: 1.5;
}

.principal>section:nth-of-type(odd){
  align-self: flex-end;
}
.principal>section:nth-of-type(1){
  align-self: center;
}

.principal>section:hover{
  background-color: #dcdddd;
  /*box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.2);*/
}

.principal>section ul{
  margin: revert;
  padding: revert;
}

.slider-inicio{
  height: 600px;
}
.slider-inicio div.mask{
  height: 560px;
}
/*.img-inicio{
  width: 100%; 
  height: auto; 
  margin: 20px 0; 
  border-radius: 10px; 
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}*/

.logos-inicio{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.logos-inicio img{
  width: auto;
  height: 70px;
  margin: 5px; 
  border-radius: 10px; 
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}

/*.logos-inicio a:last-of-type img{
  width: 50px;
} */

.logos-inicio img.c2{
  height: 130px;
}

.guide-bph{
      height: 250px;
    float: left;
    margin: 26px;
    shape-outside: margin-box;
    filter: drop-shadow(-10px 10px 3px rgb(70,70,70)) drop-shadow(-10px 10px 3px rgb(150,150,150));
}
</style>