<template>
  <div>    
    <l-map class="map-container" ref="mapStations" style="min-height: 400px" :zoom="zoom" :center="center" :bounds="stationsBounds">
  
      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"/>    
      <l-tile-layer :url="urlEsri" :options="optionsEsri" :tile-layer-class="basemapLayer" />
     
      <template v-if="selectWheatherStations">
        <l-marker v-for="station in stationsListFiltered"
          :key="station.nombre"
          :lat-lng="station.latLng"
          :icon="iconoNaranja"
        >
          <l-popup> 
            <div class="info-popup">
                <h2> Estación {{station.nroBcbb}} - {{station.estacion}} </h2>
                <p> Latitud: {{station.latitud}} </p>
                <p> Longitud: {{station.longitud}} </p>
                <p> Estado: {{station.estado}} </p>
                <img style="display:block;" class="info-img" :src="station.img"> 
                <button style="display:block;" @click="datosActuales(station.nroBcbb)"> Datos actuales </button>          
                <v-icon v-if="loadingWeatherData" name="fa-circle-notch" animation="spin"/>
            </div>             
          </l-popup>
          <l-tooltip> {{station.nroBcbb}} - {{station.estacion}} </l-tooltip>
        </l-marker>
      </template>

      <!-- Markers de freatímetros -->
      <template v-if="selectFreatimeters">
        <l-marker v-for="freatimeter in freatimetersListFiltered"
          :key="freatimeter.name"
          :lat-lng="freatimeter.loc"
        >
            <l-popup> 
            <h2> {{freatimeter.name}} </h2>
            <p> {{freatimeter.description}} </p>
            <img class="info-img" :src="freatimeter.img"> 
            
          </l-popup>
          <l-tooltip> {{freatimeter.name}} </l-tooltip>

        </l-marker>
      </template>

      <div id="map-center-rigth"> 
        <div class="layers-control">
          <div class="checkbox-group">
            <h2> Tipo </h2>
            <div class="input-group-check">                  
              <label for="in-wheather-station"> Estación Meteorológica </label>  
              <input type="checkbox" id="in-wheather-station" v-model="selectWheatherStations" >           
            </div>
            <div class="input-group-check">                  
              <label for="in-freatimeter"> Freatímetro (Próximamente)</label>  
              <input type="checkbox" id="in-freatimeter" v-model="selectFreatimeters" disabled>           
            </div>
            <div class="input-group-check">                  
              <label for="in-limnigraph"> Limnígrafo (Próximamente)</label>  
              <input type="checkbox" id="in-limnigraph" v-model="selectLimnigraphs" disabled>           
            </div>
          </div>  
        </div>
      </div>

      <div id="map-bottom-left" v-show="showDataClima">  
        <header>
          <h2> Datos actuales </h2>     
          <span class="close-x" @click="showDataClima = false"> x </span>
        </header>
        <CurrentWeather :dataClima="dataClima"> </CurrentWeather>
      </div>
    </l-map>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import L from 'leaflet';
import { basemapLayer } from 'esri-leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LControlLayers, LTooltip} from 'vue2-leaflet';
import { resourcesService } from "../_services";
import CurrentWeather from './CurrentWeather.vue';


export default {
  name: 'StationsPage',
  components:{
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlLayers,
    CurrentWeather,
    LTooltip
  },
  data() {
    return {
      name: 'StationsPage',
      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'EsriSatelitalMap',
          visible: false,
          url: 'ImageryClarity',
          attribution:'Esri',
        },
      ],
      zoom: 7.5,
      center:  [-38.2, -61.9],
      stationsBounds: [[-36.85, -63.15], [-39.05, -60.95]],
      basemapLayer,
      urlEsri: 'ImageryClarity', // the esri map url
      optionsEsri: {}, // other options, accepts all options from L.TileLayer.
      selectWheatherStations: true,
      selectFreatimeters: false,
      selectLimnigraphs: false,
      showDataClima: false,
      dataClima: {},
      loadingWeatherData: false
    }
  },
  computed: {
    ...mapState('resources', ['res_']),
    iconoNaranja(){
      return new L.Icon({
                  /* iconUrl: 'img/icons-color/marker-icon-2x-orange.png', */
                  iconUrl: 'https://portalhidro.ar/img/marker-icon-2x-orange.png',
                  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41]
                })
    } ,
    dicVariables(){
       return { temperature : 'Temperatura', wind: 'Viento', flow: 'Caudal', level: 'Nivel'}
    },
    /* stationsList(){
      return [
        {name: 'Estacion 1', loc: [-37.883236, -62.575982], color: '#FF0000', strokeColor:'#000000', circleColor:'FFFFFF', variables: ['temperature', 'wind'], img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Lago_cochico.jpg/800px-Lago_cochico.jpg', description: 'Estación ubicada en el cauce del arroyo ...'},
        {name: 'Estacion 2', loc: [-37.89, -62.56], color: '#FF0000', strokeColor:'#000000', circleColor:'FFFFFF', variables: ['temperature', 'flow'], img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Lago_cochico.jpg/800px-Lago_cochico.jpg', description: 'Estación ubicada en la cuenca de ...'},
      ]
    }, */
    stationsList(){
      return this.res_.estaciones.map(
        est => {
          let foto = this.res_.estaciones_fotos.find( f => f.nro_bcbb == est.nroBcbb)
          return {...est, latLng: [est.latitud, est.longitud], img: foto ? foto.url : 'https://portalhidro.ar/webdisk/estaciones_fotos/no-disponible.jpg'}
        }
      )
    },
    stationsImagesList(){
      return this.res_.estaciones_fotos
    },
    freatimetersList(){
      return [
        { name: 'Freatimetro 1', loc: [-37.89, -62.56], img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Lago_cochico.jpg/800px-Lago_cochico.jpg', description: 'Freatimetro perteneciente a la red ...'},
        { name: 'Freatimetro 2', loc: [-37.889, -62.57], img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Lago_cochico.jpg/800px-Lago_cochico.jpg', description: 'Freatimetro perteneciente a la red ...'},
        { name: 'Freatimetro 3', loc: [-37.89, -62.55], img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Lago_cochico.jpg/800px-Lago_cochico.jpg', description: 'Freatimetro perteneciente a la red ...'},
        { name: 'Freatimetro 4', loc: [-37.9, -62.56], img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Lago_cochico.jpg/800px-Lago_cochico.jpg', description: 'Freatimetro perteneciente a la red ...'}
      ]
    },
    stationsListFiltered(){
       return this.stationsList
    },
    freatimetersListFiltered(){
      return this.freatimetersList
    }
  },
  watch:{
    /* stationsList(){
      console.log("stationsList changed")
      this.stationsListFiltered = this.stationsList
    } */
  },
  methods:{
    /* ...mapActions('modulo', ['acciones']) */   
    datosActuales(nroBcbb){
      this.loadingWeatherData = true;
      //console.log("datos actuales de ", nroBcbb)
      resourcesService.getDatosActuales(nroBcbb)
      .then(
        data => {
          console.log("datos actuales=", data);
          // map data to dataClima object
          const temp = {
            Estacion: data.NombreCompleto,
            Temperatura: data.Temperatura.Valor + " °C",
            Viento: data.DireccionVientoDescrip.Valor + " " + data.VelocidadViento.Valor + " km/h",
            Presion: data.Presion.Valor + " hPa",
            Humedad: data.HumedadRelativa.Valor + " %",
            Icono: data.LinkIconoEstadoActualTiempo,
            Descripcion: data.DescripEstadoActualTiempo
          }

          this.dataClima = temp;
          /* this.dataClima.Temperatura = data.Temperatura.Valor + " °C";
          this.dataClima.Viento = data.DireccionVientoDescrip.Valor + " " + data.VelocidadViento.Valor + " km/h";
          this.dataClima.Presion = data.Presion.Valor + " hPa";
          this.dataClima.Humedad = data.HumedadRelativa.Valor; */
          this.showDataClima = true;
          this.loadingWeatherData = false;
        }
      )
    }
  },
  async created () {/* 
    const loadEstaciones = ()=> {
        const f = async () => {
            const r3 = await fetch('http://127.0.0.1:3000/estaciones');
            const allEstaciones = await r3.json()
            console.log("resp estaciones ==", allEstaciones)
            return allEstaciones
        }   
        f().then(
            data => {
                this.stationsList = data.map(
                    est => {
                        return {...est, latLng: [est.latitud, est.longitud]}
                    }
                )
                console.log("cantidad=", data.length)
                console.log("datos=", data)
            })
    }
    loadEstaciones() */
    /* console.log("created")
    const resp = await fetch('http://127.0.0.1:3000/estaciones');
    const allEstaciones = await resp.json();
    console.log("resp estaciones ==", allEstaciones)
    this.stationsList = allEstaciones.map(
        est => {
            return {...est, latLng: [est.latitud, est.longitud]}
        }
    ) */

  }
}
</script>

<style>
.section-help>.help{
  display: none;
}
.help-icon{
  margin: 5px;
}

.checkbox-group{
  border-radius: 10px;
  border: 1px solid black;
  padding: 10px;
  text-align: right;
}
.checkbox-group:hover{
  background-color: rgb(244 244 244);
}

.info-popup>button{
  margin: 5px;
  padding: 5px;
  background-color: #03a9f4;
  color: white;
  font-size: 11pt;
  text-align: left;
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  border: none;
  border-radius: 5px;
}

#map-bottom-left{
  position: absolute;
  bottom: 5%;
  left: 10px;
  /*width: 270px;
  height: 200px;*/
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  z-index: 1000;
}
#map-bottom-left>header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}


.close-x{
  /*position: absolute;
  top: 0;
  right: 0; */
  font-size: 14pt;
  cursor: pointer;
}

</style>