<template>
  <footer class="main-footer">
    <section class="logos">
      <img id="logo-municipio-tornquist" src='../assets/logo_municipio_Tornquist.png'> 
      <img id="logo-ggfa" src='../assets/logo_GGFA.png'> 
      <img id="logo-dgyt" src='../assets/logo_dgyt.png'>  
      <!-- <img src='../assets/logo_LCI.png'>  -->
      <img id="logo-icic" src='../assets/logo_ICIC.png'> 
      <img id="logo-uns" src='../assets/logo_UNS.png'> 
    </section>
   
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  data() {
    return {

    }
  },
  methods:{

  }
}
</script>

<style>
:root{
  --footer-height: 70px;
  --footer-bg-color: rgb(227, 235, 237);
  --footer-bg-color-dark: #84898a;
}
.main-footer{
  height: var(--footer-height);
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center; 
  /*background-color: var(--header-bg-color);*/
  background: linear-gradient(-7deg, var(--header-bg-color), var(--header-bg-color-dark) );
  padding: 0 15px;
  filter: drop-shadow(0 -5px 3px black);
  position: fixed;
  bottom: 0;
  z-index: 15;
}

.logos{
  display: flex;
  /*width: 650px;*/
  height: var(--header-height);
  justify-content: space-between;
  padding: 5px;
  margin: 0px;
  gap: 10px;
}
.logos>img{
  /* width: calc(var(--header-height) * 0.8); */
  height: calc(var(--header-height) * 0.8);
  border-radius: 10px;
}
#logo-municipio-tornquist{
  width : 200px;
}
#logo-ggfa{
  width : 65px;
}
#logo-dgyt{
  width : 60px;
}
#logo-icic{
  width : 80px;
}
#logo-uns{
  width : 80px;
}


</style>