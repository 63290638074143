import Vue from 'vue'
import App from './App.vue'

import {store} from './_store';
import {router} from './_helpers';

import 'leaflet/dist/leaflet.css';

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

/* ICONOS */
import { OhVueIcon, addIcons } from "oh-vue-icons";
import { FaFlag, FaSearch, RiZhihuFill,HiSolidMenu ,HiUserCircle, FaCaretDown  ,FaGlobe ,BiBarChartLine,BiTools ,HiLocationMarker ,GiHelp  , FaTemperatureHigh, FaWind , WiBarometer , WiHumidity , FaCircleNotch, IoLayers , WiSmallCraftAdvisory , BiClipboardData   } from "oh-vue-icons/icons";
addIcons(FaFlag, RiZhihuFill, HiSolidMenu ,HiUserCircle , FaSearch, FaCaretDown , FaGlobe ,BiBarChartLine ,BiTools ,HiLocationMarker ,GiHelp, FaTemperatureHigh, FaWind , WiBarometer  ,WiHumidity , FaCircleNotch, IoLayers , WiSmallCraftAdvisory , BiClipboardData );

/* iconos water */
import { RiContrastDrop2Fill, RiContrastDrop2Line , RiDropFill, RiDropLine } from "oh-vue-icons/icons";
addIcons(RiContrastDrop2Fill, RiContrastDrop2Line , RiDropFill, RiDropLine );

import { BiGear, IoReloadCircleSharp  } from "oh-vue-icons/icons";
addIcons(BiGear, IoReloadCircleSharp );
Vue.component("v-icon", OhVueIcon);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
