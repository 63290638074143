<template>
  <div class="weather-data"> 
    <h3> {{dataClima.Estacion}} </h3>   
    <div class="weather-descrip">
      <img :src="dataClima.Icono" alt="icono del clima">
      <div>        
        <p> {{dataClima.Descripcion}} </p>
      </div>      
    </div>
    
    <div class="col-2">
      <div class="weather-variables"> 
        <p> <v-icon name="fa-temperature-high"/> Temperatura: {{dataClima.Temperatura}}</p>
        <p> <v-icon name="fa-wind"/> Viento: {{dataClima.Viento}} </p>
      </div>
      <div class="weather-variables">
        <p> <v-icon name="wi-barometer"/> Presión: {{dataClima.Presion}} </p>
        <p> <v-icon name="wi-humidity"/> Humedad: {{dataClima.Humedad}} </p>
      </div> 
    </div>   
  </div>
</template>

<script>
//import { mapState, mapActions } from 'vuex'

export default {
  name: 'CurrentWeather',
  components:{

  },
  props:
  {
    dataClima: Object
  },
  data() {
    return {
      name: 'CurrentWeather'
      }
  },
  computed: {
    /* ...mapState('modulo', ['estados']) */
  
  },
  methods:{
    /* ...mapActions('modulo', ['acciones']) */
  }


}
</script>

<style>
.weather-data{
  /*background-color: var(--light-blue);*/
  color: rgb(7, 59, 76);
  padding: 0px;
  border-radius: 10px;
  margin: 0px;
}
.weather-descrip{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.weather-variables{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  margin: 0px;
}
</style>