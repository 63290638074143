import { resourcesService } from "../_services";



const state = { res_ : { partidos: [], cuencasN1 : [], cuencasN2 : [], cuencasN3 : [] , cursosN1 : [] ,cursosN2 : [] ,cursosN3 : [] , estaciones : [], imagenes : [], estaciones_fotos: [], anios : [], imagenesNDVI : [], imagenesNDWI : [], imagenesNDMI : [] }}

const actions = {
    getPartidos({commit, dispatch}){
        commit('resourcesRequest', 'getPartidos')
        resourcesService.getPartidos()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'partidos', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getCuencasN1({commit, dispatch}){
        commit('resourcesRequest', 'getCuencasN1')
        resourcesService.getCuencasN1()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'cuencasN1', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getCuencasN2({commit, dispatch}){
        commit('resourcesRequest', 'getCuencasN2')
        resourcesService.getCuencasN2()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'cuencasN2', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getCuencasN3({commit, dispatch}){
        commit('resourcesRequest', 'getCuencasN3')
        resourcesService.getCuencasN3()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'cuencasN3', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getCursosN1({commit, dispatch}){
        commit('resourcesRequest', 'getCursosN1')
        resourcesService.getCursosN1()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'cursosN1', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getCursosN2({commit, dispatch}){
        commit('resourcesRequest', 'getCursosN2')
        resourcesService.getCursosN2()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'cursosN2', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getCursosN3({commit, dispatch}){
        commit('resourcesRequest', 'getCursosN3')
        resourcesService.getCursosN3()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'cursosN3', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getEstaciones({commit, dispatch}, datos){
        commit('resourcesRequest', 'getEstaciones')
        resourcesService.getEstaciones(datos)
            .then(
                datos => {
                    /*  Funke 9, Villa Ventana 22, Cumbre Cordón Ventana 27, 
                        Cerro Napostá Oeste 26, 17 de Agosto 11, Dufaur 10, 
                        Arroyo Pantanoso 23, Cnel. Pringles 16, Frapal 6, 
                        Cnel. Falcón 19, Cabildo 2, Sierras Grandes 32, La Vitícola 3. */
                    const stationsToDisplay = [9,22,27,26,11,10,23,16,6,19,2,32,3];
                    /* filter statios where nroBcb is in stationsToDisplay */
                    const filteredStations = datos.filter( estacion => stationsToDisplay.includes(estacion.nroBcbb))
                    commit('resourcesSuccess', {item: 'estaciones', value: filteredStations})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getImagenes({commit, dispatch}, datos){
        commit('resourcesRequest', 'getImagenes')
        resourcesService.getImagenes(datos)
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'imagenes', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getEstacionesFotos({commit, dispatch}){
        commit('resourcesRequest', 'getEstacionesFotos')
        resourcesService.getEstacionesFotos()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'estaciones_fotos', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getAnios({commit, dispatch}){
        commit('resourcesRequest', 'getAnios')
        resourcesService.getAnios()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'anios', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getImagenesNDVI({commit, dispatch}){
        commit('resourcesRequest', 'getImagenesNDVI')
        resourcesService.getImagenesNDVI()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'imagenesNDVI', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getImagenesNDWI({commit, dispatch}){
        commit('resourcesRequest', 'getImagenesNDWI')
        resourcesService.getImagenesNDWI()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'imagenesNDWI', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    },
    getImagenesNDMI({commit, dispatch}){
        commit('resourcesRequest', 'getImagenesNDMI')
        resourcesService.getImagenesNDMI()
            .then(
                datos => {
                    commit('resourcesSuccess', {item: 'imagenesNDMI', value: datos})
                },
                error => {
                    commit('resourcesFailure', {error : error.toString()})
                    dispatch('alert/error', error, { root: true });
                }
            )
    }
}

const mutations = {
    resourcesRequest(state, req){
        console.log('@cateroriesRequest :: ', req)
    },
    resourcesSuccess(state, datos){
        state.res_[datos.item] = datos.value;
        console.log('@resourcesSuccess :: ', datos.item, datos.value )
    },
    resourcesFailure(state, {error}){
        console.log('resources.module :: resourcesFailure :: error = ' , error)
    }
}

export const resources = {
    namespaced: true,
    state,
    actions,
    mutations
}