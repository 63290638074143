<template>
  <div>
    <l-map class="map-container" style="min-height: 400px" :zoom="zoom" :center="center">
      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"/>
      <!--  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer> -->
      <l-tile-layer :url="urlEsri" :options="optionsEsri" :tile-layer-class="basemapLayer" />
         
    
    <template v-if="selectPartidos">
      <l-geo-json v-for="partido in partidosList"
        :key="partido.id"
        :geojson="JSON.parse(partido.shape)"
        :options="partidosOptions"        
        :options-style="partidosstyleFunction"
      >        
      </l-geo-json>
    </template>

    <template v-if="selectCuencasN1">
        <l-geo-json v-for="cuenca in cuencasn1List"
        :key="cuenca.hydroid"
        :geojson="cuenca.SHAPE2"
        :options="cuencasn1Options"
        :options-style="cuencasN1styleFunction"
      >
      </l-geo-json>
    </template>

    <template v-if="selectCuencasN2">
      <l-geo-json v-for="cuencan2 in cuencasn2List"
        :key="cuencan2.hydroid"
        :geojson="cuencan2.SHAPE2"
        :options="cuencasn2Options"
        :options-style="cuencasN2styleFunction"
      >        
      </l-geo-json>
    </template>

    <template v-if="selectCuencasN3">
      <l-geo-json v-for="cuencan3 in cuencasn3List"
        :key="cuencan3.hydroid"
        :geojson="cuencan3.SHAPE2"
        :options="cuencasn3Options"
        :options-style="cuencasN3styleFunction"
      >        
      </l-geo-json>
    </template>

    <template v-if="selectCursosN1">
      <l-geo-json v-for="curson1 in cursosn1List"
        :key="curson1.hydroid"
        :geojson="curson1.SHAPE2"
        :options="cursosN1Options"
        :options-style="cursoN1styleFunction"
      >        
      </l-geo-json>
    </template>

    <template v-if="selectCursosN2">
      <l-geo-json v-for="curson2 in cursosn2List"
        :key="curson2.hydroid"
        :geojson="curson2.SHAPE2"
        :options="cursosN2Options"
        :options-style="cursoN2styleFunction"
      >        
      </l-geo-json>
    </template>

    <template v-if="selectCursosN3">
      <l-geo-json v-for="curson3 in cursosn3List"
        :key="curson3.hydroid"
        :geojson="curson3.SHAPE2"
        :options="cursosN3Options"
        :options-style="cursoN3styleFunction"
      >        
      </l-geo-json>
    </template>

    <template v-if="selectImagenes">
      <l-marker v-for="imagen in imagenesList"
        :lat-lng="[imagen.geometry.coordinates[1], imagen.geometry.coordinates[0]]" 
        :key="imagen.properties.id">
          <l-popup> 
            <p class="info-title"> {{imagen.properties.descripcion}} </p>
            <img class="info-img" :src="imagen.properties.url"> 
          </l-popup>
        </l-marker>
    </template>
    

    <div id="map-center-rigth"> 
      <div class="layers-control">
        <div class="checkbox-group">
          <h2> Capas </h2>
          <div class="input-group-check">                  
            <label for="in-partidos"> Partidos </label>  
            <input type="checkbox" id="in-partidos" v-model="selectPartidos">           
          </div>
          <div class="input-group-check">                  
            <label for="in-cuencasn1"> Cuencas N1 </label>  
            <input type="checkbox" id="in-cuencasn1" v-model="selectCuencasN1">           
          </div>
          <div class="input-group-check">                  
            <label for="in-cuencasn2"> Cuencas N2 </label>  
            <input type="checkbox" id="in-cuencasn2" v-model="selectCuencasN2">           
          </div>
          <div class="input-group-check">                  
            <label for="in-cuencasn3"> Cuencas N3 </label>  
            <input type="checkbox" id="in-cuencasn3" v-model="selectCuencasN3">           
          </div>
          <div class="input-group-check">                  
            <label for="in-cursosn1"> Cauces Principales </label>  
            <input type="checkbox" id="in-cursosn1" v-model="selectCursosN1">
          </div>
          <div class="input-group-check">                  
            <label for="in-cursosn2"> Redes de drenaje </label>  
            <input type="checkbox" id="in-cursosn2" v-model="selectCursosN2">
          </div>
          <div class="input-group-check sub-input">                  
            <label for="in-cursosn3"> Alta y Baja </label>  
            <input type="checkbox" id="in-cursosn3" v-model="selectCursosN3">
          </div>
          <div class="input-group-check">                  
            <label for="in-imagenes"> Imágenes </label>  
            <input type="checkbox" id="in-imagenes" v-model="selectImagenes"> 
          </div>
        </div>  
      </div>
    </div>

    </l-map>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { basemapLayer } from 'esri-leaflet'
import {LMap, LTileLayer, LMarker, LPopup, LGeoJson, LControlLayers} from 'vue2-leaflet';
export default {
  name: 'MapsPage',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LGeoJson,
    LControlLayers
  },
  data() {
    return {
      name: 'MapsPage',
      listaPartidos: [],
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'EsriSatelitalMap',
          visible: false,
          url: 'ImageryClarity',
          attribution:'Esri',
        },
      ],
      zoom: 9,
      /* center:  [-37.883236, -62.575982], */
      center:  [-38.317, -61.866],
      markerLatLng1: [-37.883236, -62.575982],
      markerLatLng2: [-37.883236, -62.5],
      markerLatLng3: [-37.8, -62.575982],
      basemapLayer,
      urlEsri: 'ImageryClarity', // the esri map url
      optionsEsri: {}, // other options, accepts all options from L.TileLayer.
      selectPartidos: false,
      selectCuencasN1: false,
      selectCuencasN2: false,
      selectCuencasN3: false,
      selectCursosN1: false,
      selectCursosN2: false,
      selectCursosN3: false,
      selectImagenes: false
    }
  },
  computed:{
    ...mapState('resources', ['res_']),
    partidosList(){
      return this.res_.partidos
    },
    cuencasn1List(){
      return this.res_.cuencasN1
    },
    cuencasn2List(){
      return this.res_.cuencasN2
    },
    cuencasn3List(){
      return this.res_.cuencasN3
    },
    cursosn1List(){
      return this.res_.cursosN1
    },
    cursosn2List(){
      return this.res_.cursosN2
    },
    cursosn3List(){
      return this.res_.cursosN3
    },
    imagenesList(){
      return this.res_.imagenes
    },
    partidosOptions(){
        return {
            onEachFeature: this.onEachPartidoFunction
        }
    },
    cuencasn1Options(){
        return {
            onEachFeature: this.onEachCuencaN1Function
        }
    },
    cuencasn2Options(){
        return {
            onEachFeature: this.onEachCuencaN2Function
        }
    },
    cuencasn3Options(){
        return {
            onEachFeature: this.onEachCuencaN3Function
        }
    },
    cursosN1Options(){
        return {
            onEachFeature: this.onEachCursoN1Function
        }
    },
    cursosN2Options(){
        return {
            onEachFeature: this.onEachCursoN2Function
        }
    },
    cursosN3Options(){
        return {
            onEachFeature: this.onEachCursoN3Function
        }
    },    
    onEachPartidoFunction() {
      /* if (!this.enableTooltip) {
        return () => {};
      } */
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(
          `<div class="info-popup"> 
            <h2> Partido: ${feature.properties.nombre} </h2>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    partidosstyleFunction() {
      return () => {
        return {
          weight: 3,
          color: "#000000",
          opacity: 1,
          /* fill ramdom color */
          fillColor: "#"+((1<<24)*Math.random()|0).toString(16),
          fillOpacity: 0.4
        };
      };
    },
    cuencasN1styleFunction() {
      return () => {
        return {
          weight: 3,
          color: "#ECEFF1",
          opacity: 1,
          /* fill ramdom color */
          fillColor: "#"+((1<<24)*Math.random()|0).toString(16),
          fillOpacity: 0.4
        };
      };
    },
    cuencasN2styleFunction() {
      return () => {
        return {
          weight: 2,
          color: "#0000FF",
          opacity: 1,
          /* fill ramdom color */
          fillColor: "#"+((1<<24)*Math.random()|0).toString(16),
          fillOpacity: 0.4
        };
      };
    },
    cuencasN3styleFunction() {
      return () => {
        return {
          weight: 1,
          color: "#FF0000",
          opacity: 1,
          /* fill ramdom color */
          fillColor: "#"+((1<<24)*Math.random()|0).toString(16),
          fillOpacity: 0.4
        };
      };
    },
    cursoN1styleFunction() {
      return () => {
        return {
          weight: 4,
          //color: "#00FFAA",
          opacity: 1,
          /* fill ramdom color */
          color: "#"+((1<<24)*Math.random()|0).toString(16),
          //fillOpacity: 0.4
        };
      };
    },
    cursoN2styleFunction() {
      return () => {
        return {
          weight: 3,
          //color: "#FF00FF",
          opacity: 1,
          /* fill ramdom color */
          color: "#"+((1<<24)*Math.random()|0).toString(16),
          //fillOpacity: 0.4
        };
      };
    },
    cursoN3styleFunction() {
      return () => {
        return {
          weight: 2,
          //color: "#FFFF00",
          opacity: 1,
          /* fill ramdom color */
          color: "#"+((1<<24)*Math.random()|0).toString(16),
          //fillOpacity: 0.4
        };
      };
    },    
    onEachCuencaN1Function() {
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(
          /* "<div class='info-popup'> Cuenca: " +
            feature.properties.name +
          "</div>" ,*/
          `<div class="info-popup"> 
            <h2> Cuenca: ${feature.properties.name} </h2>
            <p> Nivel: 1 </p>
            <p> GNA: ${feature.properties.gna} </p>
            <p> Código hydroid: ${feature.properties.id} </p>
            <hr>
            <p> ${feature.properties.descripcion} </p>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    onEachCuencaN2Function() {
      return (feature, layer) => {
        console.log(feature);
        console.log(layer);
        layer.bindPopup(
          `<div class="info-popup"> 
            <h2> Cuenca: ${feature.properties.name} </h2>
            <p> Nivel: 2 </p>
            <p> Código hydroid: ${feature.properties.id} </p>
            <p> Cumbre: ${feature.properties.cumbre} </p>
            <p> Municipio: ${feature.properties.municipio} </p>
            <p> Área: ${feature.properties.area_km2} [km2] </p>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    onEachCuencaN3Function() {
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(
        `<div class="info-popup"> 
            <h2> Cuenca: ${feature.properties.name} </h2>
            <p> Nivel: 3 </p>
            <p> Código hydroid: ${feature.properties.id} </p>
            <p> Subcuenca nivel 3: ${feature.properties.subcca_n3} </p>
            <p> Subcuenca Nivel 2: ${feature.properties.subcca_n2} </p>
            <p> Municipio: ${feature.properties.municipio} </p>
            <p> Área: ${feature.properties.area_km2} [km2] </p>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    onEachCursoN1Function() {
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(
          `<div class="info-popup"> 
            <h2> Curso: ${feature.properties.name} </h2>
            <p> Nivel: 1 </p>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    onEachCursoN2Function() {
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(
          `<div class="info-popup"> 
            <h2> Curso: ${feature.properties.name} </h2>
            <p> Nivel: 2 </p>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    onEachCursoN3Function() {
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(
          `<div class="info-popup"> 
            <h2> Curso: ${feature.properties.name} </h2>
            <p> Nivel: 3 </p>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
  },
  methods:{
        
  },
  async created () {
    
  },
}
</script>

<style>
.leaflet.control-layers{
  text-align: left;
}
.leaflet.control-layers-list{
  text-align: left!important;
}
.leaflet.control-layers-expanded{
  text-align: left;
}
.info-img{
  width: 150px;
  height: 150px;
}
.info-title{
  max-width: 150px;
}



/* OPTIONS BAR */
.options-bar{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 15px;
  gap: 15px;
}
.input-group-check{
  height: 20px;
  line-height: 20px;
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
}

/*
.input-group-check>select{
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  height: 30px;
  background-color: rgb(226 229 233 / 72%);
}
.input-group-check>select>option{
  font-size: inherit;
}*/

.layers-control{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 15px;
  gap: 15px;
}

.checkbox-group{
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: flex-start;
}

.marker-popup{
    background-color: lightblue;
    padding: 3px;
    border-radius: 10px;
}
.marker-popup>h2{
    background-color: orange;
    font-weight: bold;
    color: white;
    margin: 0px;
    border-radius: 5px;
}
#map-center-rigth{
  position: absolute;
  top: 20%;
  right: 10px;
  /*width: 200px;*/
  /*height: 200px;*/
  background-color: white;
  padding: 3px;
  border-radius: 10px;
  z-index: 1000;
}
/*.leaflet-popup-content-wrapper{
  padding: 0px!important;
}
.leaflet-popup-content{
    margin:0px!important;
}
.leaflet-popup-tip{
    background: lightblue!important;
}*/

.info-popup{
  padding: 3px;
  border-radius: 10px;
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;
}
.info-popup>p:nth-of-type(n){
  margin: 0px;

}
.map-container{
  /*height: calc(100vh - var(--footer-height) - var(--header-height) - 20px) !important;*/

  height: calc(100vh - var(--header-height) - 20px) !important;
}

.sub-input{
  /*margin-left: 20px;*/
  align-self: center;
}
</style>