import { config } from '../_helpers';
import { handleResponse } from '../_helpers';

export const resourcesService = {
    getPartidos,
    getCuencasN1,
    getCuencasN2,
    getCuencasN3,
    getEstaciones,
    getCursosN1,
    getCursosN2,
    getCursosN3,
    getImagenes,
    getDatosActuales,
    getDatosAnuales,
    getEstacionesFotos,
    getAnios,
    getImagenesNDVI,
    getImagenesNDWI,
    getImagenesNDMI
}

function getPartidos(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/partidos`, requestOptions).then(handleResponse);
}

function getCuencasN1(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/cuencasn1`, requestOptions).then(handleResponse);
}
function getCuencasN2(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/cuencasn2`, requestOptions).then(handleResponse);
}
function getCuencasN3(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/cuencasn3`, requestOptions).then(handleResponse);
}

function getCursosN1(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/cursosn1`, requestOptions).then(handleResponse);
}

function getCursosN2(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/cursosn2`, requestOptions).then(handleResponse);
}

function getCursosN3(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/cursosn3`, requestOptions).then(handleResponse);
}   

function getEstaciones(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/estaciones`, requestOptions).then(handleResponse);
}

function getImagenes(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/imagenes`, requestOptions).then(handleResponse);
}

function getDatosActuales(nro_Bcbb){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/estaciones/${nro_Bcbb}/datos-actuales`, requestOptions).then(handleResponse);
}

function getDatosAnuales(nro_Bcbb, anio){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/estaciones/${nro_Bcbb}/datos-anuales/${anio}`, requestOptions).then(handleResponse);
}

function getEstacionesFotos(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/estaciones/fotos`, requestOptions).then(handleResponse);
}

function getAnios(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/anios`, requestOptions).then(handleResponse);
}

function getImagenesNDVI(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/productos/ndvi`, requestOptions).then(handleResponse);
}

function getImagenesNDWI(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/productos/ndwi`, requestOptions).then(handleResponse);
}

function getImagenesNDMI(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${config.apiUrl}/productos/ndmi`, requestOptions).then(handleResponse);
}