<template>
  <div>
    <template v-if="!(partidos.length>0)" >
      <div class="loader"> 
        <v-icon name="io-reload-circle-sharp" animation="spin" scale=2 /> 
        <span class="loader-text"> Cargando partidos... </span>
      </div>
    </template>

    <template v-if="!(cuencasn1.length>0)" >
      <div class="loader"> 
        <v-icon name="io-reload-circle-sharp" animation="spin" scale=2 /> 
        <span class="loader-text"> Cargando Cuencas N1... </span>
      </div>
    </template>

    <template v-if="!(cuencasn2.length>0)" >
      <div class="loader"> 
        <v-icon name="io-reload-circle-sharp" animation="spin" scale=2 /> 
        <span class="loader-text"> Cargando Cuencas N2... </span>
      </div>
    </template>

    <template v-if="!(cuencasn3.length>0)" >
      <div class="loader"> 
        <v-icon name="io-reload-circle-sharp" animation="spin" scale=2 /> 
        <span class="loader-text"> Cargando Cuencas N3... </span>
      </div>
    </template>

    <template v-if="!(cursosn1.length>0)" >
      <div class="loader"> 
        <v-icon name="io-reload-circle-sharp" animation="spin" scale=2 /> 
        <span class="loader-text"> Cargando Cauces Principales... </span>
      </div>
    </template>

    <template v-if="!(cursosn2.length>0)" >
      <div class="loader"> 
        <v-icon name="io-reload-circle-sharp" animation="spin" scale=2 /> 
        <span class="loader-text"> Cargando Redes de drenaje... </span>
      </div>
    </template>

    <template v-if="!(cursosn3.length>0)" >
      <div class="loader"> 
        <v-icon name="io-reload-circle-sharp" animation="spin" scale=2 /> 
        <span class="loader-text"> Cargando Redes de drenaje alta y baja... </span>
      </div>
    </template>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ResourcesLoaders',
  components:{

  },
  data() {
    return {
      name: 'ResourcesLoaders'
      }
  },
  computed: {
    /* ...mapState('modulo', ['estados']) */
    ...mapState('resources', ['res_']),
    partidos(){ return this.res_.partidos; },
    cuencasn1(){ return this.res_.cuencasN1; },
    cuencasn2(){ return this.res_.cuencasN2; },
    cuencasn3(){ return this.res_.cuencasN3; },
    cursosn1(){ return this.res_.cursosN1; },
    cursosn2(){ return this.res_.cursosN2; },
    cursosn3(){ return this.res_.cursosN3; },
    imagenes(){ return this.res_.imagenes; },
  
  },
  methods:{
    /* ...mapActions('modulo', ['acciones']) */
  }


}
</script>

<style>
.loader{
      margin: 5px;
    padding: 10px;
    text-align: left;
    background-color: rgb(161 231 220);
    border-radius: 5px;
}

.loader-text{
    position: relative;
    top: -7px;
}
</style>