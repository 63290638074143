<template>
  <div class="col-2">
    <div class="options-bar-vertical">
      <!-- <div class="input-group"> 
        <label for="sel-partido"> Partido: </label>
        <select id="sel-partido">
          <option v-for="partido in partidosList" :key="partido.id" :value="partido.nombre"> {{partido.name}} </option>
        </select>
      </div> -->

      <div class="input-group"> 
        <label for="sel-cuencaN1"> Cuenca N1: </label>
        <select id="sel-cuencaN1" v-model="selectedCuencaN1">
          <option v-for="cuenca in cuencasn1List" :key="cuenca.hydroid" :value="cuenca.hydroid"> {{cuenca.nam}} </option>
        </select>
      </div>
      
      <template v-if="(cuencasn2ListFiltered.length>0)&&(selectedCuencaN1!='')">
        <div class="input-group"> 
        <label for="sel-cuencaN2"> Cuenca N2: </label>
        <select id="sel-cuencaN2" v-model="selectedCuencaN2" :disabled="selectedCuencaN1==''">
          <option v-for="cuenca in cuencasn2ListFiltered" :key="cuenca.hydroid" :value="cuenca.hydroid"> {{cuenca.nam}} </option>
        </select>
      </div>
      </template>
      <template v-if="(cuencasn2ListFiltered.length==0)&&(selectedCuencaN1!='')">
        <p > No hay registros de cuencas de nivel 2, por favor seleccione otra cuenca de nivel 1 </p>
      </template>      
      
      <template v-if="(cuencasn3ListFiltered.length>0)&&(selectedCuencaN2!='')">
        <div class="input-group"> 
          <label for="sel-cuencaN3"> Cuenca N3: </label>
          <select id="sel-cuencaN3" v-model="selectedCuencaN3" :disabled="selectedCuencaN2==''">
            <option v-for="cuenca in cuencasn3ListFiltered" :key="cuenca.hydroid" :value="cuenca.hydroid"> {{cuenca.nam}} ({{cuenca.subcca_n3}}) </option>
          </select>
        </div>
      </template>
      <template v-if="(cuencasn3ListFiltered.length==0)&&(selectedCuencaN2!='')">
        <p > No hay registros de cuencas de nivel 3, por favor seleccione otra cuenca de nivel 2 </p>
      </template>
    </div>

    <l-map class="map-container" style="height: 500px" :zoom="zoom" :center="center">
      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"/>
      <!--  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer> -->
      <l-tile-layer :url="urlEsri" :options="optionsEsri" :tile-layer-class="basemapLayer" />

      <template v-if="geojsonN1">
          <l-geo-json 
          :key="geojsonN1.hydroid"
          :geojson="geojsonN1.SHAPE2"
          :options="cuencasn1Options"
          :options-style="cuencasN1styleFunction"
        >
        </l-geo-json>
      </template>

      <template v-if="geojsonN2">
        <l-geo-json
          :key="geojsonN2.hydroid"
          :geojson="geojsonN2.SHAPE2"
          :options="cuencasn2Options"
          :options-style="cuencasN2styleFunction"
        >        
        </l-geo-json>
      </template>

      <template v-if="geojsonN3">
        <l-geo-json
          :key="geojsonN3.hydroid"
          :geojson="geojsonN3.SHAPE2"
          :options="cuencasn3Options"
          :options-style="cuencasN3styleFunction"
        >        
        </l-geo-json>
      </template>    
    </l-map>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { basemapLayer } from 'esri-leaflet'
import {LMap, LTileLayer, /* LMarker,*/ /* LPopup, */  LGeoJson, LControlLayers} from 'vue2-leaflet';
export default {
  name: 'MapsFilterPage',
  components: {
    LMap,
    LTileLayer,
    /* LMarker,*/
    /* LPopup,  */
    LGeoJson,
    LControlLayers
  },
  data() {
    return {
      name: 'MapsPage',
      listaPartidos: [],
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'EsriSatelitalMap',
          visible: false,
          url: 'ImageryClarity',
          attribution:'Esri',
        },
      ],
      zoom: 9,
      /* center:  [-37.883236, -62.575982], */
      center:  [-38.317, -61.866],
      markerLatLng1: [-37.883236, -62.575982],
      markerLatLng2: [-37.883236, -62.5],
      markerLatLng3: [-37.8, -62.575982],
      basemapLayer,
      urlEsri: 'ImageryClarity', // the esri map url
      optionsEsri: {}, // other options, accepts all options from L.TileLayer.
      /* selectPartidos: false,
      selectCuencasN1: false,
      selectCuencasN2: false,
      selectCuencasN3: false, */

      selectedCuencaN1: '',
      selectedCuencaN2: '',
      selectedCuencaN3: '',
      cuencasn1ListFiltered: [],
      cuencasn2ListFiltered: [],
      cuencasn3ListFiltered: [],
      geojsonN1: null,
      geojsonN2: null,
      geojsonN3: null,
    }
  },
  watch:{
    selectedCuencaN1: function(newVal){
      console.log("selectedCuencaN1: ", newVal);
      let temp = this.cuencasn1List.filter(cuenca => cuenca.hydroid == newVal);
      if (temp.length > 0){
        this.geojsonN1 = temp[0];
        console.log("geojsonN1: ", this.geojsonN1);
      }      
      else{
        this.geojsonN1 = null;
      }
      //this.cuencasn1ListFiltered = this.cuencasn1List.filter(cuenca => cuenca.hydroid == newVal);
      this.cuencasn2ListFiltered = this.cuencasn2List.filter(cuenca => cuenca.n1_hydroid == newVal);
      this.cuencasn3ListFiltered = [];
      this.selectedCuencaN2 = '';
      this.selectedCuencaN3 = '';
      this.geojsonN2 = null;
      this.geojsonN3 = null;
    },
    selectedCuencaN2: function(newVal){
      console.log("selectedCuencaN2: ", newVal);
      let temp = this.cuencasn2List.filter(cuenca => cuenca.hydroid == newVal);
      if (temp.length > 0){
        this.geojsonN2 = temp[0];
      }
      //this.cuencasn2ListFiltered = this.cuencasn2List.filter(cuenca => cuenca.hydroid == newVal);
      this.cuencasn3ListFiltered = this.cuencasn3List.filter(cuenca => cuenca.n2_hydroid == newVal);
      this.selectedCuencaN3 = '';
      this.geojsonN3 = null;
    },
    selectedCuencaN3: function(newVal){
      console.log("selectedCuencaN3: ", newVal);
      let temp = this.cuencasn3List.filter(cuenca => cuenca.hydroid == newVal);
      if (temp.length > 0){
        this.geojsonN3 = temp[0];
      }
      //this.cuencasn3ListFiltered = this.cuencasn3List.filter(cuenca => cuenca.hydroid == newVal);
    }
  },
  computed:{
    ...mapState('resources', ['res_']),
    partidosList(){
      return this.res_.partidos
    },
    cuencasn1List(){
      return this.res_.cuencasN1
    },
    cuencasn2List(){
      return this.res_.cuencasN2
    },
    cuencasn3List(){
      return this.res_.cuencasN3
    },
    partidosOptions(){
        return {
            onEachFeature: this.onEachPartidoFunction
        }
    },
    cuencasn1Options(){
        return {
            onEachFeature: this.onEachCuencaN1Function
        }
    },
    cuencasn2Options(){
        return {
            onEachFeature: this.onEachCuencaN2Function
        }
    },
    cuencasn3Options(){
        return {
            onEachFeature: this.onEachCuencaN3Function
        }
    },
    onEachPartidoFunction() {
      /* if (!this.enableTooltip) {
        return () => {};
      } */
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(
          "<div>Partido:" +
            feature.properties.nombre +
            "</div>",
          { permanent: false, sticky: true }
        );
      };
    },
    partidosstyleFunction() {
      return () => {
        return {
          weight: 3,
          color: "#000000",
          opacity: 1,
          /* fill ramdom color */
          fillColor: "#"+((1<<24)*Math.random()|0).toString(16),
          fillOpacity: 0.4
        };
      };
    },
    cuencasN1styleFunction() {
      return () => {
        return {
          weight: 3,
          color: "#ECEFF1",
          opacity: 1,
          /* fill ramdom color */
          fillColor: "#"+((1<<24)*Math.random()|0).toString(16),
          fillOpacity: 0.2
        };
      };
    },
    cuencasN2styleFunction() {
      return () => {
        return {
          weight: 2,
          color: "#0000FF",
          opacity: 1,
          /* fill ramdom color */
          fillColor: "#"+((1<<24)*Math.random()|0).toString(16),
          fillOpacity: 0.3
        };
      };
    },
    cuencasN3styleFunction() {
      return () => {
        return {
          weight: 1,
          color: "#FF0000",
          opacity: 1,
          /* fill ramdom color */
          fillColor: "#"+((1<<24)*Math.random()|0).toString(16),
          fillOpacity: 0.8
        };
      };
    },
    onEachCuencaN1Function() {
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(          
          `<div class="info-popup"> 
            <h2> Cuenca: ${feature.properties.name} </h2>
            <p> Nivel: 1 </p>
            <p> GNA: ${feature.properties.gna} </p>
            <p> Código hydroid: ${feature.properties.id} </p>
            <hr>
            <p> ${feature.properties.descripcion} </p>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    onEachCuencaN2Function() {
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(
          `<div class="info-popup"> 
            <h2> Cuenca: ${feature.properties.name} </h2>
            <p> Nivel: 2 </p>
            <p> Código hydroid: ${feature.properties.id} </p>
            <p> Cumbre: ${feature.properties.cumbre} </p>
            <p> Municipio: ${feature.properties.municipio} </p>
            <p> Área: ${feature.properties.area_km2} [km2] </p>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    onEachCuencaN3Function() {
      return (feature, layer) => {
        console.log(feature);
        layer.bindPopup(
          `<div class="info-popup"> 
            <h2> Cuenca: ${feature.properties.name} </h2>
            <p> Nivel: 3 </p>
            <p> Código hydroid: ${feature.properties.id} </p>
            <p> Subcuenca nivel 3: ${feature.properties.subcca_n3} </p>
            <p> Subcuenca Nivel 2: ${feature.properties.subcca_n2} </p>
            <p> Municipio: ${feature.properties.municipio} </p>
            <p> Área: ${feature.properties.area_km2} [km2] </p>
          </div>`,
          { permanent: false, sticky: true }
        );
      };
    },
  },
  methods:{
        
  },
  async created () {
    
  },
}
</script>

<style>
.leaflet.control-layers{
  text-align: left;
}
.leaflet.control-layers-list{
  text-align: left!important;
}
.leaflet.control-layers-expanded{
  text-align: left;
}
.info-img{
  width: 150px;
  height: 150px;
}



/* OPTIONS BAR */
.options-bar-vertical{
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 5px;
  width: 250px;
}
.input-group{
  height: 60px;
  /*line-height: 30px;*/
  display: flex;
  flex-flow: column nowrap;
}

.input-group>select{
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  height: 30px;
  background-color: rgb(226 229 233 / 72%);
}
.input-group>select>option{
  font-size: inherit;
}

.layers-control{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 1px;
  gap: 15px;
}

.marker-popup{
    background-color: lightblue;
    padding: 3px;
    border-radius: 10px;
}
.marker-popup>h2{
    background-color: orange;
    font-weight: bold;
    color: white;
    margin: 0px;
    border-radius: 5px;
}
#map-center-rigth{
  position: absolute;
  top: 20%;
  right: 10px;
  /*width: 200px;*/
  /*height: 200px;*/
  background-color: white;
  padding: 3px;
  border-radius: 10px;
  z-index: 1000;
}
/*.leaflet-popup-content-wrapper{
  padding: 0px!important;
}
.leaflet-popup-content{
    margin:0px!important;
}
.leaflet-popup-tip{
    background: lightblue!important;
}*/

.col-2{
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
}

</style>