<template>
  <header class="main-header">

    <button class="menu-burger" @click="toogleSidebar()" >
      <v-icon name="hi-solid-menu" scale="2.2"/> 
    </button>
    <!-- <section class="logos">
      <img id="logo-municipio-tornquist" src='../assets/logo_municipio_Tornquist.png'> 
      <img id="logo-ggfa" src='../assets/logo_GGFA.png'> 
      <img id="logo-dgyt" src='../assets/logo_dgyt.png'>  
      <img id="logo-icic" src='../assets/logo_ICIC.png'> 
      <img id="logo-uns" src='../assets/logo_UNS.png'> 
    </section> -->
    <img class="logo-hidroar" src='../assets/logo_hidroar.jpg'> 
    <div class="titulos">
      <h1> HIDRO.AR </h1>
      <h2> Portal Hidroclimatológico del Municipio de Tornquist </h2>
    </div>
    
     
    <section class="user" @mouseover="showUserIcons = true" @mouseleave="showUserIcons = false">
      <p class="user-name"> Invitado </p>
      <transition name="user-fade-width">  
        <div class="user-options" v-if="showUserIcons">      
          <v-icon v-if="showUserIcons" class="user-avatar" name="hi-user-circle" scale="1.5"/>
          <v-icon v-if="showUserIcons" class="user-options" name="fa-caret-down" scale="2"/>
        </div>
      </transition>
    </section>
    
    
  </header>
</template>

<script>
export default {
  name: 'HeaderTop',
  data() {
    return {
        msg: '',
        sidebarVisible: true,
        showUserIcons: false
    }
  },
  methods:{
    toogleSidebar(){
      this.sidebarVisible = !this.sidebarVisible;
      this.$emit('toogle-sidebar', this.sidebarVisible);
      console.log(this.sidebarVisible);
    }
  }
}
</script>

<style>
:root{
  --header-height: 90px;
  --header-bg-color: rgb(227, 235, 237);
  --header-bg-color-dark: #84898a;
}
.main-header{
  height: var(--header-height);
  width: 100%;
  display: flex;
  flex-flow: row wrap; 
  align-items: center; 
  background-color: #f0f0f0;
  /*background-image: url('../assets/hero-header3.jpg');
  background-size: cover;*/


  padding: 0 15px;
  /*filter: drop-shadow(0 5px 3px black);*/
  position: fixed;
  top: 0;
  z-index: 1500; /* higher than nav*/
  color: black;
  gap: 30px;
}
/*.menu-burger{
  height: calc(var(--header-height) * 0.8);
  width: calc(var(--header-height) * 0.8);;
  margin-right: 15px;
}
.logos{
  display: flex;
  width: 650px;
  height: var(--header-height);
  justify-content: space-between;
  padding: 5px;
  margin: 0px;
}
.logos>img{
 
  height: calc(var(--header-height) * 0.8);
  border-radius: 10px;
}
#logo-municipio-tornquist{
  width : 200px;
}
#logo-ggfa{
  width : 65px;
}
#logo-dgyt{
  width : 60px;
}
#logo-icic{
  width : 220px;
}
#logo-uns{
  width : 80px;
}*/
.main-header>h1{
  /*background-color: rgb(49 63 56 / 14%);*/
  height: var(--header-height);
  line-height: var(--header-height);
  padding: 0 15px;
  text-align: left;
  /*text-shadow: 2px 2px 5px #00BCD4;*/
}

.titulos{
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.titulos h1{
  font-size: 1.5rem;
}
@media screen and (max-width: 650px){
  .titulos h1{ font-size: 1.3rem;  }
}

.titulos h2{
  font-size: 1.1rem;
}
@media screen and (max-width: 650px){
  .titulos h2{ font-size: 0.9rem;  }
}

.user{
  height: 100%;
  padding: 10px 20px;
  color: #cfd3d5;
  margin-left: auto;
  width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(37 132 147);
  border-radius: 10px;
  transition: width 1.5s;
  display: none;
}

.user>.user-name{
  display: block;
}
.user>.user-options{
  display: none;
}
.user>.user-avatar{
  display: none;
}

.user:hover>*{
  display: block;

}

.user-fade-width-enter-active, .user-fade-width-leave-active  {
  transition: all var(--toogle-time) ease;
}

.user-fade-width-enter, .user-fade-width-leave-to{
  width: 150px;
  height: 100%;
  display: block;
}

</style>