<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GraphicComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GraphicComponent
]);

export default {
  name: "BarChartSimple",
  props: {
    barData: {
      type: Object
    }
  },
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "dark"
  },
  data() {
    return {
        option :{
            title:{
                text: this.barData.title,
                left: 'center',
                /* subtext: 'Fuente: Portal Hidroclimatológico del Municipio de Torquinst', */
                textStyle: {
                    fontSize: 18,
                    fontFamily: 'Ubuntu',
                },
            },
            tooltip: {
                    trigger: "item",
                    formatter: this.barData.formatter
                    },
            xAxis: {
                type: 'category',
                data: this.barData.xData
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                data: this.barData.yData,
                type: 'bar',
                emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                        }
                }
            ],
            graphic: {
              type: 'image',
              id: 'logo',
              /* right: 200,
              top: 20, */
              right: 5,
              bottom: 5,
              z: -10,
              bounding: 'raw',
              style: {
                image: require('@/assets/logo_hidroar.jpg'), // path to your logo image
                width: 70,
                height: 70
              }
            }
        }
    };
  }
};
</script>

<style scoped>
.chart {
  height: 350px;
}
</style>