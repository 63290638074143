<template>
  <div class="col-2">
    <div class="options-bar-vertical">
      <div class="input-group"> 
        <label for="sel-year"> Año: </label>
        <select id="sel-year" v-model="selectedYear">
          <option v-for="year in yearsList" :key="year.id" :value="year.id"> {{year.name}} </option>
        </select>
      </div>

      <div class="input-group"> 
        <label for="sel-station"> Estación: </label>
        <select id="sel-station" v-model="selectedStation">
          <option v-for="station in stationsList" :key="station.id" :value="station.id"> {{station.name}} </option>
        </select>
      </div>

      <!-- loading icon -->
      <v-icon v-if="loadingWeatherData" name="fa-circle-notch" animation="spin"/>
      
    </div>

    <div class="row-2">
      <h2 v-if="dataReady"> Historial de Precipitaciones de Estación {{stationName}} - Año: {{selectedYear}} </h2>
      <BarChartSimple :barData="histoData" v-if="dataReady"></BarChartSimple>
      <CalendarChartSimple :calendarData="histoDayData" v-if="dataReady"></CalendarChartSimple>
    </div>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourcesService } from "../_services";
import BarChartSimple from './BarChartSimple.vue'
import CalendarChartSimple from './CalendarChartSimple.vue'

export default {
  name: 'HistoryWeather',
  components:{
    BarChartSimple,
    CalendarChartSimple
  },
  data() {
    return {
        name: 'HistoryWeather',
        selectedStation: '',
        selectedYear: '',
        stationName: '',
        loadingWeatherData: false,
        dataReady: false,
        histoData:
        {
          title: 'Precipitaciones Acumuladas mensuales',
          formatter: "Año 2024 <br/>{b} : {c} mm",
          xData:  ['Ene', 'Feb', 'Mar', 'May', 'Abr', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          yData: [ 120, 200, 150, 80, 70, 110, 130, 140, 160, 180, 190, 100]
        },
        histoDayData: {
          year: 2024
        }
      }
  },
  computed: {
 /*    ...mapState('modulo', ['estados']) */
    ...mapState('resources',['res_']),
    stationsList(){
      return this.res_.estaciones.map(
        est => {
          let foto = this.res_.estaciones_fotos.find( f => f.nro_bcbb == est.nroBcbb)
          return {...est, id: est.nroBcbb, name: est.estacion, latLng: [est.latitud, est.longitud], img: foto ? foto.url : 'https://portalhidro.ar/webdisk/estaciones_fotos/no-disponible.jpg'}
        }
      )
    },
    yearsList(){
      return this.res_.anios
    }
  
  },
  watch:{
    selectedYear: function(newVal){
      console.log('selectedYear', newVal)
      if (newVal && this.selectedStation){
        this.getHistoryWeather(this.selectedStation, newVal)
      }
    },
    selectedStation: function(newVal){
      console.log('selectedStation', newVal)
      if (newVal && this.selectedYear){
        this.getHistoryWeather(newVal, this.selectedYear)
        this.stationName = this.stationsList.find( s => s.nroBcbb == newVal).estacion
      }
    }
  },
  methods:{
    /* ...mapActions('modulo', ['acciones']) */    
    getHistoryWeather: function(nroBcbb, year){
      this.loadingWeatherData = true
      this.dataReady = false
      console.log('getHistoryWeather', year, nroBcbb)
      resourcesService.getDatosAnuales(nroBcbb, year)
      .then(
        data => {
          //console.table(data)
          this.loadingWeatherData = false

          let monthData = [0,0,0,0,0,0,0,0,0,0,0,0]

          data.forEach( d => {
            let mes = Number(d.fecha.split('T')[0].split('-')[1])
            // [BUG] estacion 1 año 2016 tiene datos con precipAcum = null
            if (d.cantReg > 0 && d.precipAcum != null){              
              monthData[mes - 1] += d.precipAcum
            }           
          })
          
          // round monthData to 2 decimals
          monthData = monthData.map( m => Math.round(m * 100) / 100)

          // get station name
          let station = this.stationsList.find( s => s.nroBcbb == nroBcbb)
          console.log('monthData', monthData)
          console.log('station', station)
          this.histoData = {
              title: 'Precipitaciones acumuladas mensuales',
              //title: 'Precipitaciones acumuladas mensuales \n Estación ' + nroBcbb + ' - ' + station.estacion +' - Año ' + year ,
              formatter: "Año " + year + "<br/>{b} : {c} mm",
              xData:  ['Ene', 'Feb', 'Mar', 'May', 'Abr', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
              yData: monthData
            }

          // histoDayData
          let max = 0;
          let precip = data.map( d => {
            let date = d.fecha.split('T')[0];
            let p = (d.cantReg > 0 && d.precipAcum != null) ? d.precipAcum : 0;
            if (p > max) max = p;
            return [date, p]
          })
          this.histoDayData = {
            year: year,
            data: precip,
            maxValue: max,
            //title: 'Precipitaciones diarias \n Estación ' + nroBcbb + ' - ' + station.estacion +' - Año ' + year
            title: 'Precipitaciones diarias'
          }
          this.dataReady = true
        }
      )
    }
  }


}
</script>

<style>
.row-2{
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 15px;
}
</style>