const state = {
    view: {target: null}
}

const actions = {
    setView({ commit }, target) {
        commit('setView', target);
    }
};

const mutations = {
    setView(state, target ) {
        state.view.target = target;
    }
};

export const ui = {
    namespaced: true,
    state,
    actions,
    mutations
};