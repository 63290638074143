import Vue from "vue";
import Router from 'vue-router';

// Components
import LoginPage from '../components/LoginPage.vue';
import HomePage from '../components/HomePage.vue';


Vue.use(Router);

export const router = new Router({
    mode: 'hash',
    routes: [
        {path: '/', component: LoginPage},
        {path: '/home', component: HomePage},
        {path: '*', redirect: '/'}
    ]
});

router.beforeEach((to, from, next) =>{
    //const publicPages = ['/'];
    next();
})