<template>
  <div class="water-alert">
    <h2> Disponibilidad actual de recurso hídrico superficial</h2>
    <!-- <v-icon name="ri-drop-line" v-if="level == 0" fill="red" scale="3"/>
    <v-icon name="ri-contrast-drop-2-line" v-if="level == 1" fill="orange" scale="3"/>
    <v-icon name="ri-contrast-drop-2-fill" v-if="level == 2" fill="yellow" scale="3"/>
     -->
    <v-icon name="ri-drop-fill" v-if="level == 0" fill="red" scale="3"/>
    <v-icon name="ri-drop-fill" v-if="level == 1" fill="orange" scale="3"/>
    <v-icon name="ri-drop-fill" v-if="level == 2" fill="yellow" scale="3"/>
    <v-icon name="ri-drop-fill" v-if="level == 3" fill="green" scale="3"/>
    <p v-if="level == 0"> ESCASO </p>
    <p v-if="level == 1"> BAJO </p>
    <p v-if="level == 2"> REGULAR </p>
    <p v-if="level == 3"> NORMAL </p>
  </div>
</template>

<script>
/* import { mapState, mapActions } from 'vuex' */

export default {
  name: 'WaterAlert',
  components:{

  },
  props: ['level'],  
  data() {
    return {
      name: 'WaterAlert'
      }
  },
  computed: {
    /* ...mapState('modulo', ['estados']) */
  
  },
  methods:{
    /* ...mapActions('modulo', ['acciones']) */
  }


}
</script>

<style>
.water-alert{
  margin-top: 20px;
  margin-bottom: auto;
  background-color: rgb(255 255 255);
  padding: 10px;
  border-radius: 10px;  
  width: calc(var(--sidebar-width) * 0.9);
}
.water-alert h2{
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 10pt;
  font-weight: normal;
}

.water-alert p{
  text-align: center;
  font-weight: bold;
  margin: 5px 0;
  padding: 2px;
  font-size: 10pt;
}
</style>