import Vue from "vue";
import Vuex from 'vuex';

import {ui} from './ui.module';
import {alert} from './alert.module';
import {resources } from './resources.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules:{
        ui,
        alert,
        resources
    }

});