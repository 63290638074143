<template>
  <div>
    <transition name="slide-fade">       
        <nav class="left-nav" v-if="isVisible">
          <div class="menu-dropdown" v-for="m in menu" :key="m.title">
            <button class="btn-menu"  @click="setTarget(m.target)"> 
            <v-icon :name="m.icon"/>
            {{m.title}}          
          </button>
          <div class="dropdown-content" v-if="m.items.length>0">
            <button class="btn-submenu" v-for="item in m.items" :key="item.title"  @click="setTarget(item.target)"> <v-icon :name="item.icon"> </v-icon> {{item.title}} </button>
          </div>
          </div>

          <WaterAlert :level="alertLevel" ></WaterAlert>
        </nav>

    </transition>
  </div>
  
</template>

<script>
import { mapActions } from 'vuex'
import WaterAlert from './WaterAlert.vue';

export default {
  name: 'MenuLeft',
  components:{
    WaterAlert
  },
  props:['isVisible'],
  data() {
    return {
        menu: [
          { title: 'Vista de capas', 
            items: [
              {title: 'Capas', target:'MapsPage', icon: 'io-layers'}, 
              {title: 'Filtro', target: 'MapsFilterPage', icon: 'fa-search'}
            ], 
            target: 'MapsPage', icon:'fa-globe'},

          /* {title: 'Búsqueda', items: [], target: 'MapsFilterPage', icon:'fa-search'}, */
          { title: 'Estadísticas', items: [], target: 'StatsPage', icon:'bi-bar-chart-line' },
          { title: 'Herramientas', items: [], target: 'ToolsPage', icon:'bi-tools' },
          { title: 'Estaciones', 
            items: [
              { title: 'Ubicaciones', target: 'StationsPage', icon:'hi-location-marker'},
              { title: 'Histórico', target: 'HistoryWeatherPage', icon:'bi-clipboard-data'}
            ], 
            target: 'StationsPage', icon:'wi-small-craft-advisory' },
        ],
        expanded: true,
        nav_msg: 'Ocultar'
    }
  },
  computed:{
    alertLevel(){
      return 0;
    }
  },
  methods:{
    ...mapActions('ui',['setView']),
    setTarget(target){
      this.setView(target);
    }
  }
}
</script>

<style>
:root{
  --sidebar-width: 150px;
  --toogle-time: 0.3s;
}
.left-nav{
  display: flex;
  flex-direction: column;
  top: calc(var(--header-height) * 1.1);
  bottom: 0;
  position: fixed;
  justify-content: flex-start;
  background-color: rgb(227, 235, 237); 
  padding: 5px;
  width: var(--sidebar-width);

  /*si se usa top, no es necesario padding*/
  /*padding-top: calc(var(--header-height) * 1.2);*/

  /*padding-bottom: calc(var(--footer-height) * 1.2);*/
  z-index: 5;
  left: 0;
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  gap: 3px;
  filter: drop-shadow(3px 0px 2px black);
}

.slide-fade-enter-active, .slide-fade-leave-active  {
  transition: all var(--toogle-time) ease;
}

.slide-fade-enter, .slide-fade-leave-to{
  left: calc(var(--sidebar-width) * -1)
}

.menu-dropdown{
  width: calc( var(--sidebar-width) * 0.9);
  position: relative;
}
.dropdown-content {
  display: none;
  position: absolute;
  left: 20px;  
  max-width: 120px;
  box-shadow: -10px 0px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-bottom: 1px;
  margin-top: 5px;
}
.menu-dropdown:hover .dropdown-content {
  display: block;
  z-index: 50;
  position: relative;
  
}
.btn-menu{
  height: 35px;
  width: 100%;
  background-color: #607D8B;
  color: white;
  font-size: 11pt;
  text-align: left;
  padding-left: 5px;
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  border: none;
}

.btn-menu:hover{
  transform: scale(1.05);
}

.btn-submenu{
  height: 30px;
  width: 100%;
  background-color: #607D8B;
  color: white;
  font-size: 10pt;
  text-align: left;
  padding-left: 5px;
  
  font-family: 'Nerko One', cursive;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Ubuntu', sans-serif;

  border: none;
}
.btn-submenu:hover{
  background-color: #20353f;
}

</style>