<template>
  <div>
    <HeaderTop v-on:toogle-sidebar="onToogleSidebar"></HeaderTop>
    <MenuLeft :isVisible="sidebarVisible"></MenuLeft>
    <div class="top-container"> 
      <transition name="slide-fade-width"> 
        <template  v-if="sidebarVisible"> 
          <div class="vertical"> </div>      
        </template>
      </transition>      
      <main>
        <ResourcesLoaders></ResourcesLoaders>
        <component v-bind:is="view.target" > </component>    
      </main>
    </div>
    <!-- <MainFooter></MainFooter> -->
  </div>
  
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MenuLeft from './MenuLeft.vue';
import MapsPage from './MapsPage.vue';
import MapsFilterPage from './MapsFilterPage.vue';
import StatsPage from './StatsPage.vue';
import HeaderTop from './HeaderTop.vue';
import MainFooter from './MainFooter.vue';
import ToolsPage from './ToolsPage.vue';
import StationsPage from './StationsPage.vue';
import HistoryWeatherPage from './HistoryWeatherPage.vue';
import ResourcesLoaders from './ResourcesLoaders.vue';

export default {
  name: 'HomePage',
  components: {/* 
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LGeoJson, */
    MenuLeft,
    MapsPage,
    MapsFilterPage,
    StatsPage,
    HeaderTop,
    MainFooter,
    ToolsPage,
    StationsPage,
    HistoryWeatherPage,
    ResourcesLoaders
  },
  data() {
    return {
        msg: 'pagina de inicio',
        sidebarVisible: true
    }
  },
  computed:{
    ...mapState('resources',['res_']),
    ...mapState('ui', ['view']),
    listaPartidos_raw(){
      return this.res_.partidos
    }

  },
  watch:{
    listaPartidos_raw: function(newVal){
      this.listaPartidos = newVal;
    }
  },
  
  methods:{
    ...mapActions('resources',['getPartidos','getEstaciones','getCuencasN1','getCuencasN2','getCuencasN3','getCursosN1','getCursosN2','getCursosN3','getImagenes', 'getEstacionesFotos', 'getAnios']),
    onToogleSidebar: function (value) {
      this.sidebarVisible = value;
      /* console.log(value); */
    },
    testApi(){
      this.getPartidos();
    },
    evento(msg){
      alert(msg)
    }
  },
  async created () {
    this.getPartidos();
    this.getEstaciones();
    this.getCuencasN1();
    this.getCuencasN2();
    this.getCuencasN3();
    this.getCursosN1();
    this.getCursosN2();
    this.getCursosN3();
    this.getImagenes();
    this.getEstacionesFotos();
    this.getAnios();
  }
}
</script>
<style>
.top-container{
  display:flex;
}
.vertical{
  width: calc(var(--sidebar-width) * 1.05);
  /* linea siguiente activar si se visualiza el footer*/
  /*height: calc(100vh - var(--footer-height) - var(--header-height) - 20px) !important;*/
  
  /*background-color: #c6d2dc;*/
  filter: drop-shadow(3px 0px 2px black);

}
.slide-fade-width-enter-active, .slide-fade-width-leave-active  {
  transition: all var(--toogle-time) ease;
}

.slide-fade-width-enter, .slide-fade-width-leave-to{
  width: 0;
}
main{
  width: calc(100vw - var(--sidebar-width) - 5px);
  margin-left: 5px;
  padding: 5px;
  padding-left: 10px;
  /*height: calc(100vh - var(--footer-height) - var(--header-height) - 20px) !important;*/
  
  /* linea siguiente activar si se visualiza el footer*/
  /*padding-bottom: var(--footer-height);*/
}
</style>