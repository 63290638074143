<template>
  <div>
    <h2> Productos FITBA </h2>    
    
    <section class="products">
      <header>
        <h4> NDVI </h4>
        <h5> El NDVI (Indice de Vegetación de Diferencia Normalizada) es un índice simple ampliamente usado para evaluar la vegetación. Mide el estado de salud de la vegetación. El rango de valores del NDVI es de -1 a 1.  Los valores cercanos a -1 indican presencia de agua mientras que los valores mayores que 0.2 indican vegetación. </h5>
      </header>

      <div class="gallery">
        <article class="gallery-item" v-for="product in productsNDVIListPage" :key="product.id">
          <img :src="product.url" :alt="product.descripcion">
          <p> {{ product.descripcion }} </p>
        </article>
      </div>
      <!-- pagination -->
      <div class="gallery-pagination">
        <button :disabled="currentNDVIPage==1" @click="setCurrentNDVIPage(currentNDVIPage - 1)"> Anterior </button>
        <nav class="gallery-nav">
          <ul v-for="page in pagesNDVI" :key="page">
            <li 
              :key="'pageNDVI_'+page" 
              @click="setCurrentNDVIPage(page)"
              :class="{active: currentNDVIPage == page}"
              > {{ page }} </li>
          </ul>
        </nav>
        <button :disabled="currentNDVIPage == pagesNDVI.length" @click="setCurrentNDVIPage(currentNDVIPage + 1)"> Siguiente </button>
      </div>
    </section>

    <section class="products">
      <header>
        <h4> NDWI </h4>
        <h5> El Índice de Agua de Diferencia Normalizada (NDWI) es utilizado para el monitoreo de cuerpos de agua. Los valores de NDWI son positivos para los elementos de agua y negativos (o cero) para el suelo y la vegetación terrestre. </h5>
      </header>

      <div class="gallery">
        <article class="gallery-item" v-for="product in productsNDWIListPage" :key="product.id">
          <img :src="product.url" :alt="product.descripcion">
          <p> {{ product.descripcion }} </p>
        </article>
      </div>
      <!-- pagination -->
      <div class="gallery-pagination">
        <button :disabled="currentNDWIPage==1" @click="setCurrentNDWIPage(currentNDWIPage - 1)"> Anterior </button>
        <nav class="gallery-nav">
          <ul v-for="page in pagesNDWI" :key="page">
            <li 
              :key="'pageNDWI_'+page" 
              @click="setCurrentNDWIPage(page)"
              :class="{active: currentNDWIPage == page}"
              > {{ page }} </li>
          </ul>
        </nav>
        <button :disabled="currentNDWIPage == pagesNDWI.length" @click="setCurrentNDWIPage(currentNDWIPage + 1)"> Siguiente </button>
      </div>
    </section>

    <section class="products">
      <header>
        <h4> NDMI </h4>
        <h5> El NDMI( Indice de Humedad de Diferencia Normalizada) permite la detección de los niveles de humedad en la vegetación.  Permite monitorear el estrés hídrico en los cultivos y supervisar el riego. Los valores van entre -1 y 1. El estrés hídrico es representado por valores negativos que se acercan a -1, mientras que valores cercanos a 1 pueden indicar anegamiento o cobertura total de dosel.  </h5>
      </header>

      <div class="gallery">
        <article class="gallery-item" v-for="product in productsNDMIListPage" :key="product.id">
          <img :src="product.url" :alt="product.descripcion">
          <p> {{ product.descripcion }} </p>
        </article>
      </div>
      <!-- pagination -->
      <div class="gallery-pagination">
        <button :disabled="currentNDWIPage==1" @click="setCurrentNDMIPage(currentNDMIPage - 1)"> Anterior </button>
        <nav class="gallery-nav">
          <ul v-for="page in pagesNDMI" :key="page">
            <li 
              :key="'pageNDMI_'+page" 
              @click="setCurrentNDMIPage(page)"
              :class="{active: currentNDMIPage == page}"
              > {{ page }} </li>
          </ul>
        </nav>
        <button :disabled="currentNDMIPage == pagesNDMI.length" @click="setCurrentNDMIPage(currentNDMIPage + 1)"> Siguiente </button>
      </div>
    </section>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ToolsPage',
  components:{

  },
  data() {
    return {
      name: 'ToolsPage',
      currentNDVIPage: 1,
      currentNDWIPage: 1,
      currentNDMIPage: 1
      }
  },
  computed: {
 /*    ...mapState('modulo', ['estados']) */
    ...mapState('resources',['res_']),
    productsNDVIList(){
      return this.res_.imagenesNDVI
      /* return [
          {
            id: 1,
            descripcion: 'Imagen 1',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 2,
            descripcion: 'Imagen 2',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 3,
            descripcion: 'Imagen 3',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 4,
            descripcion: 'Imagen 4',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 5,
            descripcion: 'Imagen 5',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 6,
            descripcion: 'Imagen 6',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 7,
            descripcion: 'Imagen 7',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 8,
            descripcion: 'Imagen 8',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 9,
            descripcion: 'Imagen 9',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 10,
            descripcion: 'Imagen 10',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          }

        ] */
    },
    pagesNDVI(){
      // calculate number of pages
      let pages = this.productsNDVIList.length / 3;
      // if there is a remainder, add one more page
      if(this.productsNDVIList.length % 3 > 0){
        pages++;
      }
      // generate an array with the number of pages
      const pagesArray = [];
      for(let i = 1; i <= pages; i++){
        pagesArray.push(i);
      }
      return pagesArray;
    },
    productsNDVIListPage(){
      // calculate the start and end index of the current page
      const start = (this.currentNDVIPage - 1) * 3;
      const end = start + 3;
      // return the products of the current page
      return this.productsNDVIList.slice(start, end);
    },
    productosNDWIList(){
      return this.res_.imagenesNDWI
      /* return [
          {
            id: 1,
            descripcion: 'Imagen 1',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 2,
            descripcion: 'Imagen 2',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 3,
            descripcion: 'Imagen 3',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          }
        ] */
    },
    pagesNDWI(){
      // calculate number of pages
      let pages = this.productosNDWIList.length / 3;
      // if there is a remainder, add one more page
      if(this.productosNDWIList.length % 3 > 0){
        pages++;
      }
      // generate an array with the number of pages
      const pagesArray = [];
      for(let i = 1; i <= pages; i++){
        pagesArray.push(i);
      }
      return pagesArray;
    },
    productsNDWIListPage(){
      // calculate the start and end index of the current page
      const start = (this.currentNDWIPage - 1) * 3;
      const end = start + 3;
      // return the products of the current page
      return this.productosNDWIList.slice(start, end);
    },
    productosNDMIList(){
      return this.res_.imagenesNDMI
      /* return [
          {
            id: 1,
            descripcion: 'Imagen 1',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 2,
            descripcion: 'Imagen 2',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          },
          {
            id: 3,
            descripcion: 'Imagen 3',
            url: 'https://portalhidro.ar/webdisk/productos_fitba/fitba_ndvi_1.png'
          }
        ] */
    },
    pagesNDMI(){
      // calculate number of pages
      let pages = this.productosNDMIList.length / 3;
      // if there is a remainder, add one more page
      if(this.productosNDMIList.length % 3 > 0){
        pages++;
      }
      // generate an array with the number of pages
      const pagesArray = [];
      for(let i = 1; i <= pages; i++){
        pagesArray.push(i);
      }
      return pagesArray;
    },
    productsNDMIListPage(){
      // calculate the start and end index of the current page
      const start = (this.currentNDMIPage - 1) * 3;
      const end = start + 3;
      // return the products of the current page
      return this.productosNDMIList.slice(start, end);
    }   
  },
  methods:{
    /* ...mapActions('modulo', ['acciones']) */
    ...mapActions('resources', ['getImagenesNDVI', 'getImagenesNDWI', 'getImagenesNDMI']),
    setCurrentNDVIPage(page){
      this.currentNDVIPage = page;
      console.log('current page: ', this.currentNDVIPage);
    },
    setCurrentNDWIPage(page){
      this.currentNDWIPage = page;
      console.log('current page: ', this.currentNDWIPage);
    },
    setCurrentNDMIPage(page){
      this.currentNDMIPage = page;
      console.log('current page: ', this.currentNDMIPage);
    }
  },
  async created () {
    this.getImagenesNDVI();
    this.getImagenesNDWI();
    this.getImagenesNDMI();
  }
}
</script>

<style>

.products{
  width: 100%;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  gap: 40px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  min-height: 300px;
}

.products header, .products footer{
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: #f4f4f4;
}

.products>header{
  color: #333;
}

.gallery-item{
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}

.gallery-item img{
  width: 90%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;  
}

.gallery-item p{
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

/* styles for pagination +/ */
.gallery-pagination{
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  width: fit-content;
  padding: 2px 5px;
}

.gallery-pagination button{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f4f4f4;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}

.gallery-pagination button:hover{
  background-color: #ccc;
}

.gallery-pagination>.gallery-nav{
  display: inline-block;
}

.gallery-pagination>.gallery-nav>ul{
  display: inline-block !important;
  position: static !important;
  list-style: none;
  padding: 0;
  margin: 0;
}

.gallery-pagination>.gallery-nav>ul>li{
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f4f4f4;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}

.gallery-pagination>.gallery-nav>ul>li.active{
  background-color: rgb(104 208 239);
}

.gallery-pagination>.gallery-nav>ul>li:hover{
  background-color: #ccc;
}

/* styles for footer */
.products>footer{
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: #f4f4f4;
}

.products>footer p{
  font-size: 20px;
  font-weight: bold;
  color: #333;
  width: 100%;
}


</style>