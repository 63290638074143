<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { CalendarComponent, VisualMapComponent, GraphicComponent } from 'echarts/components';
import { HeatmapChart } from 'echarts/charts';

echarts.use([
  CalendarComponent,
  VisualMapComponent,
  HeatmapChart,
  CanvasRenderer,
  GraphicComponent
]);

import VChart, { THEME_KEY } from "vue-echarts";

export default {
  name: "CalendarChartSimple",
  props: {
    calendarData: {
      type: Object
    }
  },
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "dark"
  },
  data() {
    return {
        option :{
            title:{
                text: this.calendarData.title,
                textStyle: {
                    fontSize: 18,
                    fontFamily: 'Ubuntu',
                },
                left: 'center',
                bottom: '15%',
                subtext: 'Fuente: Portal Hidroclimatológico del Municipio de Torquinst',
            },
            visualMap: {
                show: false,
                min: 0,
                max: this.calendarData.maxValue,
                type: 'piecewise',
                orient: 'horizontal',
                inRange: {
                    color: ['#FFFFFF', '#0000FF']
                },
            },
            tooltip: {
                position: 'top',
                trigger: "item",
                // function calback for formatter
                formatter: function (params) {
                    // get the date
                    const date = new Date(params.value[0]);

                    // get name of day
                    let adjustedDate = new Date(date.getTime() + 24 * 60 * 60 * 1000); // subtract one day
                    const day = adjustedDate.toLocaleString('es-ES', { weekday: 'long' }).charAt(0).toUpperCase() + adjustedDate.toLocaleString('es-ES', { weekday: 'long' }).slice(1);


                    var value = (params.value[1] == null) ? 'N/A' : params.value[1];
                    return day + '<br/>' + params.value[0] + '<br/> <b>' + value + 'mm</b>' ;
                }
            },
            calendar: {
                range: ''+this.calendarData.year,
                cellSize: [15,15]
            },
            monthLabel: {
                // customizing the name of month
                nameMap: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
            },
            dayLabel: {
                nameMap: ['D', 'L', 'M', 'M', 'J', 'V', 'S']
            },
            series: {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: this.calendarData.data, //this.getVirtualData(''+this.calendarData.year)
                
            },
            graphic: {
              type: 'image',
              id: 'logo',
              /* right: 200,
              top: 20, */
              right: 5,
              bottom: 5,
              z: -10,
              bounding: 'raw',
              style: {
                image: require('@/assets/logo_hidroar.jpg'), // path to your logo image
                width: 70,
                height: 70
              }
            }
        }
    }   
  },
  methods: {
    getVirtualData: function (year) {
        year = year || '2017';
        var date = +echarts.number.parseDate(year + '-01-01');
        var end = +echarts.number.parseDate(year + '-12-31');
        var dayTime = 3600 * 24 * 1000;
        var data = [];
        for (var time = date; time < end; time += dayTime) {
            data.push([
                echarts.format.formatTime('yyyy-MM-dd', time),
                Math.floor(Math.random() * 10000)
            ]);
        }
        return data;
    }    
  },

}
</script>

<style>
.chart {
  height: 350px;
}
</style>
